import React from 'react';
export default class BarDetailView extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    console.log("RENDERING BAR DETAIL");
    console.log("props are");
    console.log(this.props);
    return (
      <div>Detail view here
          {this.props.bar.crowdfunding_url}
      </div>
    );
  }
}

