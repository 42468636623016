import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import CloseIcon from '@material-ui/icons/Close';
import InstagramIcon from '@material-ui/icons/Instagram';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Dashboard from './Dashboard'
import SignInScreen from './signin';
import NewFirebaseAuth from './signin';
import  {Link as RouterLink} from "@reach/router" ;
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import Slide from "@material-ui/core/Slide"
import Zoom from "@material-ui/core/Zoom"
import { ListItem } from '@material-ui/core';
import {isMobile} from 'react-device-detect'
import firebase from 'firebase';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Drink Together
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//function Transition(props){
//  return <Slide  direction="up" {...props} />
//  //return <Zoom  {...props}  />
//}
const Transition = React.forwardRef(function Transition(props,ref){
  //return <Slide direction="up" ref={ref} {...props}/>;
  return <Zoom direction="up" ref={ref} {...props}/>;
});

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  topImage: {
    paddingTop: '56.25%', // 16:9
    height: 100 
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

//<Button size="small" color="primary" variant="contained" target="_blank" href={getJoinLink(bar.primaryMeetingID)}></Button>
export default function Album(props) {
  const classes = useStyles();
  console.log("bars are")
  console.log(props.bars);
  const [isOpen, setOpen] = useState(false); 
  const [focusedBar, setFocusedBar] = useState({});
  const [currentUser, setCurrentUser] = useState({}); 

  function handleClickOpen(bardetails) {
    console.log("clicked open");
    setOpen(true);
    setFocusedBar(bardetails);
  };
  function handleClickClose() {
    console.log("clicked close");
    setOpen(false);
    setFocusedBar({});
  };
  return (
    <React.Fragment>
      <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClickClose}
      TransitionComponent={Transition}
      >
       <Dashboard onClose={handleClickClose} barDetails={focusedBar} ></Dashboard> 
      </Dialog>

      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <LocalBarIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
           Drink Together 
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
      It's almost time for cinco de mayo, order some margaritas from one of the bars below and join a virtual happy hour from our home page.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={1} justify="center">
                <Grid item>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          <a href="http://instagram.com/drink2gether" target="_blank"><InstagramIcon className={classes.icon} href="" target="_blank"/></a>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Want to add a virtual happy hour for your bar? Email info@drinktogether or DM @drink2gether on instagram
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
