import React from 'react';
import StyledFireBaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { spacing } from '@material-ui/system'


export default class SignInScreen extends React.Component {
  state = {
    isSignedIn: false,
    topLevelField: "blahblah",
    initialized: false,
    userHasZoomAccount: false,
    zoomTimedOut: false,
    secondsTillNextAttempt: "",
    zoomAccountStatusMessage: "Hold on while we check if you have a zoom account",
    zoomCheckFailed: false,
    currentUser:{},
  }

  //for listening for zoom signup confirmation event
  //this does not work with the proxy setting in package.json
  //so you need to set whatevery underlying port you actually mean rather than 3000
  //ws = new WebSocket("ws://localhost:9000/zoomsignups");
  //should pull from environment variable/kubernetes configmap
  //to know if in local dev or production
  //ws = new WebSocket("wss://drinktogether.com/zoomsignups");

  //eventSource = new EventSource("http://localhost:9000/events?stream=messages");
  //eventSource = new EventSource("http://localhost:9000/events/my-channel");
  //eventSource = new EventSource("/events/my-channel");

  //ws.addEventListener('message', event => {
  //  console.log("Received websocket event: ",event.data);
  //});

  self = this;
  onSuccessfulSignin = function () {
    console.log("called signInSuccessWithAuthResult");
    console.log("onSuccessfulSignin")

    this.setState({initialized:true});
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        //console.log("Auth State changed!!!")
        this.setState({ isSignedIn: !!user })
        //console.log("state in onSuccessfulSignin",this.state)
    }
    );
  }.bind(this);

  

  CreateNewFirestoreUser(userID, email, displayName) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "userID": userID })
    };
    fetch('/api/v1/users', requestOptions)
      .then(res => res.json())
      .then((data) => {
        this.setState({ newUserResponse: data });
        if (data.statusCode == 200) {
          this.setState({ userHasZoomAccount: true });
        }
      }).catch(console.log)
  }

  
  //fetch from firebase user table
  //to restore hasZoom state, etc.
  GetUser(userID){
    fetch('/api/v1/users?userID='+userID)
      .then(res => res.json())
      .then((data) => {
        //console.log("HIT GETUSER");
        //console.log(data);
        if (!!data) {
          this.setState({ currentUser: data });
          this.setState({ userHasZoomAccount: data.hasZoomAccount});
          //if (!data.hasZoomAccount){
          //  this.ws.send(firebase.auth().currentUser.uid);
          //}
          //console.log("user looks like");
          //console.log(data);
        }
      }).catch(console.log)
  }


  UserHasZoomAccount(email, displayName) {
    fetch('/api/v1/users/hasZoom?email=' + email + '&displayName=' + displayName).then(res2 => res2.json())
            .then((payload) => {
              if (payload.statusCode == 200) {
                this.setState({ userHasZoomAccount:true });
              } else {
                console.log("timed out should be true now")
                this.setState({ zoomTimedOut:true }) ;
              }
            }
            )
    }

  uiConfig = {
    signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
    signInSuccessUrl: '/signedIn',
    credentialHelper:'none',
    signInOptions: [
      {
      provider:firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      },
      {
      provider:firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      },
      {
      provider:firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      requireDisplayName: true
      }
      
    ],
    callbacks: {
      // Called when the user has been successfully signed in.
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        //if (authResult.user) {
        //  this.handleSignedInUser(authResult.user);
        //}
        console.log("called signInSuccessWithAuthResult")
        if (authResult.additionalUserInfo) {
          //document.getElementById('is-new-user').textContent = authResult.additionalUserInfo.isNewUser ? 'New User' : 'Existing User';
          if (authResult.additionalUserInfo.isNewUser) {
              this.CreateNewFirestoreUser(authResult.uid,authResult.email,authResult.displayName)
              //this.AddUserToAllMeetings(authResult.uid)
            console.log("registering for zoom because email is verified")
          } else {
            console.log("old user");
            this.GetUser(authResult.uid);
          }
        }
        // Do not redirect.
        return false;
      },
      //signInSuccessWithAuthResult : (authResult,redirectUrl) =>{
      //  this.onSuccessfulSignin()
      //}
    }
  };
  //arrow function avoids the need to bind(this)
  //with normal function, this is in different context
  componentDidMount() {
    //this.eventSource.onmessage = function(event){
    //    console.log(event.data);
    //  }

    /*this.ws.addEventListener("open", event => {
      console.log("websocket connected", event.data);
    })
    this.ws.addEventListener("message", event => {
      if (event.data == "timedout"){
        this.setState({zoomTimedOut:true})
      }else if (event.data == "success"){
        this.setState({userHasZoomAccount:true})
        this.setState({zoomCheckFailed:false});
      }else if (event.data == "failure"){
        this.setState({zoomCheckFailed:true});
      } else if (event.data.split(":")[0]== "status")
      this.setState({zoomAccountStatusMessage: event.data.split(":")[1]})
      console.log("received websocket message", event.data);
    })*/

    //console.log("called componentDidMount")
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.props.onAuthStateChanged(user);
        //console.log("user is", user);
        //crappy way to check if user is new

        if (!!user && (user.metadata.creationTime === user.metadata.lastSignInTime) && (localStorage.getItem('registeredEmail')!==user.email)) {
          //console.log("User was new");
          if(user.emailVerified){
            this.CreateNewFirestoreUser(user.uid, user.email, user.displayName);
            //this.AddUserToAllMeetings(user.uid);
            //console.log("registering for zoom because email is verified");
          }
          localStorage.setItem("registeredEmail",user.email)
        } else if (!!user){
          //console.log("User was old");
          this.GetUser(user.uid);
        }
        this.setState({ isSignedIn: !!user })
      }
    );
    //console.log("componentDidMount END")
  }

  //componentDidMount() {
  //  //this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
  //  //  function(user) { 
  //  //    console.log("authStateCHANGED!!!");
  //  //    console.log(user);
  //  //    this.setState({ isSignedIn: !!user });
  //  //  }.bind(this)
  //  //);
  //}

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    
            //<a href="" onClick={() => { firebase.auth().signOut(); localStorage.removeItem("registeredEmail"); }}>Sign-out</a>
    if (!this.state.isSignedIn) {
      return (
        <div>
          <StyledFireBaseAuth  uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      );
    }else{
      
      return (
            <a href="" onClick={() => { firebase.auth().signOut(); localStorage.removeItem("registeredEmail"); }}>Sign-out</a>
      )
    }
  }
}