import React from 'react';
import StyledFireBaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import './App.css';
import { Router, Link} from "@reach/router"
import Album from './Album'
import Cinco from './Cinco'
import SignInScreen from './signin';
import ProfileEditor from './profileeditor';
import BarDetailView from './bardetail';
import Switch from '@material-ui/core/Switch'
import Zoom from '@material-ui/core/Zoom';

//var firebaseConfig = {
//  apiKey: "AIzaSyDzEGyKv7L8pluiPLxrU1GBi9fuVEHzlOs",
//  authDomain: "fir-test-11f0b.firebaseapp.com",
//  databaseURL: "https://fir-test-11f0b.firebaseio.com",
//  projectId: "fir-test-11f0b",
//  storageBucket: "fir-test-11f0b.appspot.com",
//  messagingSenderId: "1048452776910",
//  appId: "1:1048452776910:web:17132766af09fcc898619f",
//  measurementId: "G-53H0ELQBV7"
//};
  //authDomain: "drinktogether-e09e5.firebaseapp.com",
var firebaseConfig = {
  apiKey: "AIzaSyDalPT9sT2RGizZVBLqNvyL1BvKM54YcWU",
  authDomain: "app.drinktogether.com",
  databaseURL: "https://drinktogether-e09e5.firebaseio.com",
  projectId: "drinktogether",
  storageBucket: "drinktogether.appspot.com",
  messagingSenderId: "261642362373",
  appId: "1:261642362373:web:e56533024fc9ab1938466c",
  measurementId: "G-H240Z0TP09"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

//dynamically setup the routes for all the bars in database
class App extends React.Component {
  state = {
    bars: allBarDetails
  }
  constructor(props) {
    super(props);
    this.state ={
      bars: allBarDetails,
      ws: null,
    };
  }

  //ws = new WebSocket("wss://drinktogether.com/zoomsignups");
  //ws = new WebSocket("ws://localhost:9000/livemetrics");

  componentDidMount() {

    //this.ws.addEventListener("open", event => {
    //  console.log("websocket connected", event.data);
    //})
    this.connect()

    fetch('/api/v1/bars')
      .then(res => res.json())
      .then((data) => {
        this.setState({ bars: data });
        //console.log("bars are", this.state.bars)
      }).catch(console.log)

  }
  timeout = 250;

  connect = () => {
    var ws = new WebSocket("wss://drinktogether.com/livemetrics");
    //var ws = new WebSocket("ws://localhost:9000/livemetrics");
    //var ws = new WebSocket("ws://localhost:3000/ws");
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
        console.log("connected websocket main component");

        this.setState({ ws: ws });

        that.timeout = 250; // reset timer to 250 on open of websocket connection 
        clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    // websocket onclose event listener
    ws.onclose = e => {
        console.log(
            `Socket is closed. Reconnect will be attempted in ${Math.min(
                10000 / 1000,
                (that.timeout + that.timeout) / 1000
            )} second.`,
            e.reason
        );

        that.timeout = that.timeout + that.timeout; //increment retry interval
        connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
        console.error(
            "Socket encountered error: ",
            err.message,
            "Closing socket"
        );

        ws.close();
    };
};

check = () => {
  const { ws } = this.state;
  if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
};


  componentWillUnmount(){
    this.state.ws.close()
  }

  render() {
    const allBars = bars.map((bar, index) => {
      var linkPath = "/bar/" + bar.name
      return (
        <BarDetailView key={bar.name} path={linkPath} bar={bar} />
      );
    })
    return (
      <div className="App">
        <Router>
          <Album bars={this.state.bars} participantsSocket={this.state.ws} path="/" />

          <Cinco path="/cincodemayo"/>
          {allBars}
        </Router>
      </div>
    );
  }
}


var bars = [ { "name":"bloodhound","crowdfunding_url":"http://www.gofundme.com/bloodhound"},
  { "name":"biig","crowdfunding_url":"http://www.bigg.com"}]

var allBarDetails = [
  { "primaryMeetingID": "89729376769",
  "detailsParagraph": "Drink together was created to help support San Francisco's bars during this difficult time and to help people have fun even though they can't go out.",
   "paymentRequired": false, "name": "All Day Happy Hour", 
  "affiliatedBarID": "abcd","thumbnailDescription":"Don't have a favorite bar? Just hop in here and drink with someone new","thumbnailURL":"https://lh3.googleusercontent.com/RLdmzkfPxZGniX4X-m-hflGcf994tO0IbXB5NTIAKUt5fx8UsJuuo-8eT_8vrMP3Kvm7bwbhWDbOL9zykJlwEqH6WQXs6r-ZFh4_0Bg"},
  { "primaryMeetingID": "85757349535", 
  "orderingURL":"https://instagram.com/biig_94",
  "crowdfundingURL":"http://givebutter.com/Biig_94",
  "thumbnailDescription":"The finest bespoke cocktails, now available to go!",
  "paymentRequired": false, 
  "name": "Biig", "thumbnailURL": "https://lh3.googleusercontent.com/8i6jkr_bQMrbY2UF8Qrffx9i4OHi5dKBa2-N7MAs7txJtelnA7W0WSJlbj6CRaqVAmN7f-fD1ZXG89GQtqoIDvHMsOdKfn4so_hJjEyv",
  "detailsParagraph":"Biig is a bespoke cocktail bar serving up anything you can dream of. Order before 2pm for same day pickup at 116 Taylor St.",
},
  { "primaryMeetingID": "87893770706", "paymentRequired": false, 
  "name": "Rye",
  "orderingURL":"https://rye-on-the-road.square.site",
  "thumbnailDescription":"Fresh made daily bottled cocktails to go",
  "detailsParagraph":"Support the team behind Rye, 15 Romolo, and The Cordial by ordering some cocktails from the link above. Cocktails are available Tuesday-Saturday, orders placed before 2pm can be delivered same day within SF city limits for a $10 fee or picked up at 688 Geary Street.",
  "thumbnailURL":"https://lh3.googleusercontent.com/EdnuSQol-tmH5mwXWswiHquXQ029EF9KzfEythJZJskpPan5zPgFKeZZPeQJir_HhAtWrcyZg7w6Yh1wHhNkVBW4-4tvkI8RPycZwE0" },
  { "primaryMeetingID": "85805570948", 
  "orderingURL":"https://bit.ly/PCHxPH",
  "thumbnailDescription":"Cocktails paired with delicious Filipino food","paymentRequired": false, "name": "Pacific Cocktail Haven",
  "detailsParagraph":"PCH has partnered with Pinoy Heritage to provide Filipino food alongside their excellent cocktails. Place your order by 10pm the night before for pickup on Monday, Wednesday, and Friday. Support the bar by picking up giftcards from the link below",
  "crowdfundingURL":"https://www.giftfly.com/shop/pacific-cocktail-haven",
  "thumbnailURL" :"https://lh3.googleusercontent.com/X6KRHGmWCL3CQWALvSElw-7Ums3NNAAvu2AR2eMpi3c1T2mQP9AO7FnNvwTZyQD445l2tpl3eLeuRW4Xr5AFEbqlQbyndlpXpGMUaRg"},
  
]

export default App;
