import React, {useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import CloseIcon from '@material-ui/icons/Close';
import InstagramIcon from '@material-ui/icons/Instagram';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Dashboard from './Dashboard'
import SignInScreen from './signin';
import NewFirebaseAuth from './signin';
import  {Link as RouterLink} from "@reach/router" ;
import Dialog from "@material-ui/core/Dialog"
import Fade from "@material-ui/core/Fade"
import Slide from "@material-ui/core/Slide"
import Zoom from "@material-ui/core/Zoom"
import { ListItem, Badge } from '@material-ui/core';
import {isMobile} from 'react-device-detect'
import firebase from 'firebase';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Drink Together
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//function Transition(props){
//  return <Slide  direction="up" {...props} />
//  //return <Zoom  {...props}  />
//}
const Transition = React.forwardRef(function Transition(props,ref){
  //return <Slide direction="up" ref={ref} {...props}/>;
  return <Zoom direction="up" ref={ref} {...props}/>;
});

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  topImage: {
    paddingTop: '56.25%', // 16:9
    height: 100 
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

//<Button size="small" color="primary" variant="contained" target="_blank" href={getJoinLink(bar.primaryMeetingID)}></Button>
export default function Album(props) {
  const classes = useStyles();
  //console.log("bars are");
  //console.log(props.bars);
  const [isOpen, setOpen] = useState(false); 
  const [focusedBar, setFocusedBar] = useState({});
  const [currentUser, setCurrentUser] = useState({}); 
  const [participantCounts, setParticipantCounts] = useState({});

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  //useEffect is called AFTER every render
  //https://reactjs.org/docs/hooks-overview.html
  
  //useEffect(() => {
  //  function handleMessage(message){

  //  }
  //  props.participantsSocket.addEventListener("message", event => {
  //    var blah = JSON.parse(event.data);
  //    console.log("participant keys" + Object.keys(blah));
  //    //TODO this is causing infinite loop
  //    setParticipantCounts(blah);
  //    console.log("meeting has " +blah["81991915456"])
  //    console.log(participantCounts)
  //  });
  //  return () => {props.participantsSocket.removeEventListener('message')}
  //});


useEffect(() => {
  function handleMessage(event) {
    var blah = JSON.parse(event.data);
    //console.log("participant keys" + Object.keys(blah));
    //TODO this was causing infinite loop before moving into this function
    setParticipantCounts(blah);
    //console.log("meeting has " + blah["81991915456"])
    console.log(participantCounts)
    return false
  }
    console.log(props.participantsSocket)
    if (!!(props.participantsSocket)){
      console.log("adding event listener")
      props.participantsSocket.addEventListener("message",handleMessage);
    }

    return () => {if (!!props.participantsSocket){props.participantsSocket.removeEventListener('message',handleMessage)}}
  });

  function getJoinLink(meetingID){
    if (isMobile) {
      //return "zoommtg://zoom.us/join?confno=87288274138&pwd=1234567890"
      return "zoomus://zoom.us/join?confno=" + meetingID+"&pwd=" 
    } 
      //return "zoommtg://zoom.us/join?confno=87288274138&pwd=1234567890"
      return "zoommtg://zoom.us/join?confno=" + meetingID +"&pwd="
  }

  function openMeeting(meetingID) {
    var userID = firebase.auth().currentUser.uid;
    console.log("hit openmeeting with meetingID " + meetingID);
    fetch('/currentcreds?userID=' + userID).then(res => res.json())
      .then((payload) => {
        window.open(getJoinLink(meetingID) + payload.password)
      }
      )
  }

  function handleClickOpen(bardetails) {
    console.log("clicked open");
    setOpen(true);
    setFocusedBar(bardetails);
  };
  function handleClickClose() {
    console.log("clicked close");
    setOpen(false);
    setFocusedBar({});
  };
  return (
    <React.Fragment>
      <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClickClose}
      TransitionComponent={Transition}
      >
       <Dashboard onClose={handleClickClose} barDetails={focusedBar} ></Dashboard> 
      </Dialog>

      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <LocalBarIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
           Drink Together 
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
             Let's grab a drink 
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Shelter in place got you down? Sick of talking to the same people every day? Sign in to join via zoom below and order a drink from your favorite SF bars. 
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={1} justify="center">
                <Grid item>
                  <SignInScreen onAuthStateChanged={(user)=>{setCurrentUser(user)}}/>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit https://source.unsplash.com/random*/}
          <Grid container spacing={4}>
            {props.bars.map((bar, card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={bar.thumbnailURL}
                    height={50}
                    title={bar.name}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {bar.name}
                    </Typography>
                    <Typography>
                     {bar.thumbnailDescription} 
                    </Typography>
                  </CardContent>
                  <CardActions>
                    
                    <Badge badgeContent={participantCounts[bar.primaryMeetingID]} color="secondary">
                      <Button disabled={!(!!currentUser && currentUser.emailVerified)} size="small" color="primary" variant="contained" target="_blank" onClick={() => openMeeting(bar.primaryMeetingID)}>
                      Join
                    </Button>
                    </Badge>
                    <Button onClick={() => handleClickOpen(bar)}>Details</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          <a href="http://instagram.com/drink2gether" target="_blank"><InstagramIcon className={classes.icon} href="" target="_blank"/></a>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Want to add a virtual happy hour for your bar? Email info@drinktogether or DM @drink2gether on instagram
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
