import React from 'react';
import firebase from 'firebase';

export default class ProfileEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isSignedIn: false
    }
  }
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      function (user) {
        console.log("authStateCHANGED!!!");
        console.log(user);
        this.setState({ isSignedIn: !!user });
      }.bind(this)
    );
  }
  changeDisplayName() {
    var user = firebase.auth().currentUser;
    user.updateProfile({
      displayName: this.state.displayName
    })
  }
  render() {
    var blah = <div>Not signed in</div>
    if (this.state.isSignedIn){
        var user = firebase.auth().currentUser;
        blah = <form onSubmit={this.changeDisplayName} >
            <input type="text" value={this.state.value} onChange={(event)=>this.inputChangedHandler(event)}/>
            <button onClick={this.changeDisplayName} >WTF</button>
          </form>

    }
    /*<form onSubmit={this.changeDisplayName}>
            <input type="text" value={this.state.value}/>
            <button onClick={this.changeDisplayName}/>
          </form>*/
    return (
      <div>
        {blah}
      </div>
    )
  }
}
