import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Button from '@material-ui/core/Button';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function BarDetails(props) {
  const classes = useStyles();
    var crowdfunding = <div></div>
    var ordering = <div></div>
    if(props.details.crowdfundingURL){
        crowdfunding = <div>
        <Link color="primary" href={props.details.crowdfundingURL} target="_blank">
        Contribute to crowdfunding
        </Link>
      </div>
    }
    if(props.details.orderingURL){
      ordering=<Typography component="p" variant="h4">
      <Button variant="contained" href={props.details.orderingURL} target="_blank" color="primary">Order drinks</Button>
      </Typography>
    }

  return (
    <React.Fragment>
      <Title>Details</Title>
      {ordering}
      <Typography component="p" color="textSecondary" className={classes.depositContext}>
      {props.details.detailsParagraph} 
      </Typography>

      {crowdfunding}
      
    </React.Fragment>
  );
}
